import type { IPlayerVideo } from '../../atoms/PlayerVideo'
import type { IVideoExpired } from '../../atoms/VideoExpired'
import type { Video as VideoObject } from '../../../types/video'
import type { IPicture } from '../../../types/pictures'
import { useAmp } from 'next/amp'
import dynamic from 'next/dynamic'
import { ComponentType } from 'react'
import { mixins, theme } from '../../../styles'
import { PlayerLazyload } from '../../atoms/PlayerLazyload'
import { Icon } from '../../atoms/Icon'
import { Link } from '../../atoms/Link'
import { PreviewVideo } from '../../atoms/PreviewVideo'
import { Picture } from '../Picture'

const PlayerVideo: ComponentType<IPlayerVideo> = dynamic(() =>
  import('../../atoms/PlayerVideo').then((module) => module.PlayerVideo),
)
const VideoExpired: ComponentType<IVideoExpired> = dynamic(() =>
  import('../../atoms/VideoExpired').then((module) => module.VideoExpired),
)

export interface IVideo {
  video: VideoObject
  title: string
  autoplay: boolean
  pictures?: IPicture
  link?: string
  previewVideoUrl?: string
  format: '16-9' | '9-16'
}

const PLAYER_ID = 'video-player-id'

export function Video({
  title,
  video,
  autoplay,
  pictures,
  link,
  previewVideoUrl,
  format,
}: IVideo): JSX.Element {
  const isAmp = useAmp()

  if (!video && !link) {
    return null
  }

  const isVideoVertical = format === '9-16'
  const {
    video_id,
    title: videoTitle,
    program,
    restriction,
    specificFields,
    duration,
    isExpired,
  } = video || {}
  const blockTitle = title || videoTitle

  return (
    <>
      <div className="Video__Wrapper flex flex-column">
        {program?.name && (
          <div className="Video__Details_Wrapper flex">
            <div className="Video__Icon">
              <Icon name="video-logo" size="30px" />
            </div>
            <div className="Video__Details flex flex-column">
              <span className="Video__Details_Title">{blockTitle}</span>
              <span className="Video__Details_Source">
                <span className="Video__Details_Label">Source : </span>
                {program?.name}
              </span>
            </div>
          </div>
        )}
        {isVideoVertical ? <Picture {...pictures} /> : null}
        <div className="Video__Player">
          {isExpired ? (
            <VideoExpired video={video} />
          ) : link && !isAmp ? (
            <Link url={link}>
              <PreviewVideo
                url={previewVideoUrl}
                pictures={pictures}
                duration={duration}
                isVideoVertical={isVideoVertical}
                triggerType="scroll"
              />
            </Link>
          ) : (
            <PlayerLazyload
              title={blockTitle}
              image={specificFields?.image}
              pictures={pictures}
              loadOn="scroll"
            >
              <PlayerVideo
                containerId={`${PLAYER_ID}-${video_id}`}
                videoId={video_id}
                withPip={!isVideoVertical}
                placeholderTheme="light"
                autoplay={!!autoplay}
                floatOnPlay={autoplay}
                restricted={restriction?.restricted}
                restrictionWordings={restriction?.wordings}
              />
            </PlayerLazyload>
          )}
        </div>
      </div>
      <style jsx>{`
        .Video__Wrapper {
          position: ${isVideoVertical ? 'relative' : 'static'};
          grid-template-columns: auto ${isVideoVertical ? '315px' : '100%'} auto;
        }

        .Video__Player {
          padding: ${video.isExpired && !link?.length ? '0 30px' : '0'};
          max-width: ${isVideoVertical ? '375px' : '100%'};
          aspect-ratio: ${isVideoVertical ? '9/16' : '16/9'};
        }

        .Video__Player :global(> div) {
          position: ${isVideoVertical ? 'static' : 'relative'};
        }

        .Video__Player :global(.PlayerVideo) {
          position: ${isVideoVertical ? 'static' : 'relative'};
        }

        @media ${mixins.mediaQuery.tablet} {
          .Video__Wrapper {
            grid-template-columns: auto ${isVideoVertical ? '375px' : '100%'} auto;
          }
        }
      `}</style>
      <style jsx>{`
        .Video__Wrapper {
          display: grid;
          margin-bottom: 50px;
          grid-template-areas:
            'title title title'
            '. video .';
        }

        .Video__Icon {
          padding: 0 10px 0 0;
        }

        .Video__Details_Wrapper {
          grid-area: title;
          padding: 20px 30px;
        }

        .Video__Details_Source {
          color: ${theme.cssVars.deepBlue};
          font-size: 13px;
          font-weight: 700;
          line-height: 154%;
        }

        .Video__Details_Label {
          color: ${theme.cssVars.midBlue};
          ${mixins.fonts.base.small}
        }

        .Video__Details_Title {
          color: ${theme.cssVars.deepBlue};
          font-size: 20px;
          font-weight: 800;
          font-family: ${theme.cssVars.overpass};

          padding-top: 1px;
        }

        .Video__Player {
          width: 100%;
          position: relative;
          grid-area: video;
        }

        .Video__Wrapper :global(> .Picture) {
          display: inline-block;
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          grid-row: 2 / 2;
          grid-column: 1 / 4;
          overflow: hidden;
        }

        .Video__Wrapper :global(> .Picture img) {
          width: 100%;
          height: 100%;
        }

        .Video__Wrapper :global(> .Picture::after) {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          mix-blend-mode: hard-light;
          background: ${theme.cssVars.infoBlue};
          opacity: 0.25;
        }

        .Video__Wrapper :global(> .Picture img) {
          filter: blur(5px) contrast(128%) hue-rotate(0deg) invert(0%) opacity(100%) saturate(120%)
            sepia(0%);
          mix-blend-mode: none;
        }

        @media ${mixins.mediaQuery.tablet} {
          .Video__Wrapper {
            margin: 0 30px 50px;
          }
        }
      `}</style>
    </>
  )
}
