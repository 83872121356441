import type { BlockData } from '../../templates/Block'

import { mixins, theme } from '../../../styles'
import { Link } from '../../atoms/Link'

export function Breadcrumb({ elementList }: BlockData<'breadcrumb'>): JSX.Element {
  return (
    <>
      <nav role="navigation" aria-label="Vous êtes ici" data-module="breadcrumb">
        <ul className="Breadcrumb__Wrapper">
          {elementList.map(({ title, link }, index) => {
            return (
              <li key={index} className="Breadcrumb__Element">
                {index ? <span className="Breadcrumb__Separator">-</span> : null}
                {link ? <Link url={link}>{title}</Link> : <span aria-current="page">{title}</span>}
              </li>
            )
          })}
        </ul>
      </nav>
      <style jsx>{`
        .Breadcrumb__Wrapper {
          padding: 30px ${theme.block.marginLR}px;
          color: ${theme.cssVars.fontColor};
          list-style: none;
        }

        .Breadcrumb__Element {
          display: inline;
        }

        .Breadcrumb__Wrapper :global(a) {
          color: ${theme.cssVars.midBlue};
        }

        :global(#body-header) .Breadcrumb__Wrapper {
          padding: 15px ${theme.block.marginLR}px;
          background-color: ${theme.cssVars.lightBlue};
          font-size: 13px;
        }

        .Breadcrumb__Separator {
          margin: 0 10px;
        }

        @media ${mixins.mediaQuery.maxWidth} {
          :global(#body-header) .Breadcrumb__Wrapper {
            padding-left: calc(
              calc(100vw - ${theme.layout.maxWidth - theme.block.marginLR * 2}px) / 2
            );
          }
        }
      `}</style>
    </>
  )
}
