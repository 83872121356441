import { useEffect, useState } from 'react'

interface IUseIntersection {
  ref: HTMLElement
  onEnter?: ({ disconnect }: { disconnect: IntersectionObserver['disconnect'] }) => void
  onLeave?: ({ disconnect }: { disconnect: IntersectionObserver['disconnect'] }) => void
  options?: IntersectionObserverInit
}

export function useIntersection({ ref, onEnter, onLeave, options }: IUseIntersection): {
  inView: boolean
} {
  const [inView, setinView] = useState(false)

  useEffect(() => {
    const handleIntersection: IntersectionObserverCallback = (entries, observer) => {
      if (entries[0].isIntersecting && !inView) {
        setinView(true)
        onEnter && onEnter({ disconnect: observer.disconnect })
      }
      if (!entries[0].isIntersecting && inView) {
        setinView(false)
        onLeave && onLeave({ disconnect: observer.disconnect })
      }
    }

    if (ref) {
      const observableOptions: IntersectionObserverInit = {
        root: null,
        rootMargin: '0px',
        threshold: [0.1],
        ...options,
      }

      const observer = new IntersectionObserver(handleIntersection, observableOptions)
      observer.observe(ref)

      return function clean() {
        observer.disconnect()
      }
    }
  }, [options, ref, onEnter, onLeave])

  return { inView }
}
