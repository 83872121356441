import { useContext, useEffect, useState } from 'react'
import { NotificationsContext } from '../../../contexts/NotificationsContext'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { Icon } from '../../atoms/Icon'
import { SVGIcon } from '../../atoms/SVGIcon'
import { getCookie, setCookie } from '../../../helpers/cookies'
import { formatSimpleDate } from '../../../helpers/time'
import slugify from 'slugify'
import { theme } from '../../../styles'

const BOUNCE_NOTIFICATION_COOKIE = 'bounceNotification'

export interface IBounceNotification {
  maxAge?: number
}

export function BounceNotification({ maxAge }: IBounceNotification): JSX.Element {
  const { notifications } = useContext(NotificationsContext)
  const { hit } = useContext(TagCommanderContext)
  const [showNotification, setShowNotification] = useState(false)
  const notification = notifications?.[0]
  const notificationDate = notification?.date || null

  useEffect(() => {
    const bounceNotificationCookie = parseInt(getCookie(BOUNCE_NOTIFICATION_COOKIE), 10)
    const lastSeenTime = isNaN(bounceNotificationCookie) ? 0 : bounceNotificationCookie
    setShowNotification(!!notificationDate && new Date(notificationDate) > new Date(lastSeenTime))
  }, [notificationDate])

  function handleClose() {
    setShowNotification(false)

    hit(
      {
        screen_clickableElementName: `push-col-gauche_${slugify(notification.title, {
          lower: true,
        })}_${formatSimpleDate(notification.date)}`,
      },
      { isClickEvent: true },
    )

    if (!maxAge) return

    const expireAt = new Date(Date.now() + maxAge * 1000 * 60 * 60)
    setCookie({
      name: BOUNCE_NOTIFICATION_COOKIE,
      value: Date.now().toString(),
      expireAt,
    })
  }

  return (
    <>
      <div
        className={`BounceNotification flex${
          showNotification ? ' BounceNotification__Active' : ''
        }`}
      >
        <span className="BounceNotification__Indicator" />
        <SVGIcon
          name="bell"
          size={24}
          primaryColor={theme.cssVars.white}
          secondaryColor={theme.cssVars.white}
        />
        <a
          href={notification?.link || ''}
          className="BounceNotification__Content flex flex-column"
          onClick={handleClose}
        >
          {notification?.originalTitle ? <small>{notification?.originalTitle}</small> : null}
          <p>
            {notification?.hasMultiLive ? <SVGIcon name="direct" size={16} /> : null}
            {notification?.originalText || ''}
          </p>
        </a>
        <Icon name="close" onClick={handleClose} size="24px" primaryColor={theme.cssVars.white} />
      </div>

      <style jsx>{`
        .BounceNotification {
          position: relative;
          width: 315px;
          background: ${theme.cssVars.deepBlue};
          color: ${theme.cssVars.white};
          border-radius: 8px;
          padding: 16px;
          gap: 8px;

          transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
          transform: translateY(100%);
          opacity: 0;
        }

        .BounceNotification__Active {
          transform: translateY(0);
          opacity: 1;
        }

        .BounceNotification::before {
          content: '';
          position: absolute;
          z-index: 1;
          top: 14px;
          left: 38px;
          height: 12px;
          width: 12px;
          background-color: ${theme.cssVars.red};
          border-radius: 50%;
        }

        .BounceNotification :global(> svg) {
          flex-shrink: 0;
        }

        .BounceNotification__Content {
          gap: 5px;
        }

        .BounceNotification__Content small {
          font-size: 13px;
          font-weight: 600;
          line-height: 145%;
        }

        .BounceNotification__Content p {
          padding: 0;
          margin: 0;
          font-size: 17px;
          font-weight: 800;
          line-height: 146%;
        }

        .BounceNotification__Content p :global(svg) {
          margin-right: 5px;
          margin-bottom: -2px;
          display: inline;
        }
      `}</style>
    </>
  )
}
