import type { IPicture } from '../../../types/pictures'

import { useAmp } from 'next/amp'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { PictureElementList } from '../PictureElementList'

export interface IPlayerLazyload {
  children: ReactNode
  title: string
  image: string
  pictures?: IPicture
  loadOn?: 'click' | 'scroll'
}

export function PlayerLazyload({
  children,
  title,
  image,
  pictures,
  loadOn = 'click',
}: IPlayerLazyload): JSX.Element {
  const isAmp = useAmp()
  const placeHolderRef = useRef()
  const [isLoaded, setIsLoaded] = useState(false)

  const handleScroll: IntersectionObserverCallback = (entries, observer) => {
    if (entries[0].isIntersecting) {
      setIsLoaded(true)
      observer.disconnect()
    }
  }

  useEffect(() => {
    if (loadOn === 'scroll' && !isAmp) {
      const options: IntersectionObserverInit = {
        root: null,
        rootMargin: '0px',
        threshold: [0.1],
      }

      const observer = new IntersectionObserver(handleScroll, options)
      observer.observe(placeHolderRef.current)
      return function clean() {
        observer.disconnect()
      }
    }
  }, [])

  return isLoaded || isAmp ? (
    <>{children}</>
  ) : (
    <>
      <button
        aria-label="Lire la vidéo"
        className="PlayerLazyload"
        onClick={() => setIsLoaded(true)}
        ref={placeHolderRef}
      >
        {pictures?.elementList?.length ? (
          <PictureElementList {...pictures} alt={title} />
        ) : (
          <img className="PlayerLazyload__Image" src={image} alt={title} loading="lazy" />
        )}
        <img
          className="PlayerLazyload__Play"
          src="/images/icons/player-logo-play.svg"
          alt="Logo lecture"
        />
      </button>
      <style jsx>{`
        .PlayerLazyload {
          position: relative;
          cursor: pointer;
          width: 100%;
          padding-top: 56.25%;
          border: none;
          border-radius: 4px;
          overflow: hidden;
        }

        .PlayerLazyload :global(> picture > img),
        .PlayerLazyload__Image {
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
        }

        .PlayerLazyload__Play {
          position: absolute;
          width: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;

          height: 1.5em;
          text-align: center;
          font: 48px/1.5 sans-serif;
          color: white;
          text-shadow: 0 0 0.5em black;
        }
      `}</style>
    </>
  )
}
